import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { User, Code, Newspaper, Linkedin, Github, MailOpen, Briefcase, GraduationCap, MapPin, Database } from "lucide-react";
import "./PortfolioWebsite.css";


const BackgroundVideo = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: -1,
    }}
  >
    <video
      autoPlay
      loop
      muted
      style={{
        minWidth: "100%",
        minHeight: "100%",
        objectFit: "cover",
      }}
    >
      <source src="/assets/vid3_slow.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

const Card = ({ icon, title, description, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.a
      className="card"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={{ height: 200 }}
      animate={{ height: isHovered ? 240 : 200 }}
      transition={{ duration: 0.3 }}
      href={link}
      target="_blank"
    >
      <motion.div
        className="card-content"
        animate={{ y: isHovered ? -40 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.img
          src={icon}
          alt={title}
          className="card-icon"
          animate={{ scale: isHovered ? 0.8 : 1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.h3 transition={{ duration: 0.3 }}>{title}</motion.h3>
      </motion.div>
      <AnimatePresence>
        {isHovered && (
          <motion.div
            className="card-description"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            <p>{description}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.a>
  );
};

const PortfolioWebsite = () => {
  const [currentSection, setCurrentSection] = useState("about");

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const projects = [
    {
      title: "Visuai",
      icon: "/assets/logo.png",
      description:
        "Text-to-image GenAI application transforming e-books into vivid picture books.",
      link: "https://www.visuai.io",
    },
    {
      title: "Digital Music Consultancy",
      icon: "/assets/greg.png",
      description:
        "Custom websites for high-profile music industry clients, increasing funnel by 500%.",
      link: "https://www.gregmaceachern.com",
    },
    {
      title: "Moolah AI",
      icon: "/assets/moolah.png",
      description:
        "On device analysis and insights of personal spending",
      link: "https://moolah.money",
    },
    {
      title: "Eatro AI",
      icon: "/assets/eatro.png",
      description: "iOS App to modify any recipe. An experiment into AI wrapper apps.",
      link: "https://eatro.co",
    },
    {
      title: "Ghola AI",
      icon: "/assets/ghola2.png",
      description: "Visualization of characters from books & stories.",
      link: "https://ghola.co",
    },
    {
      title: "Github",
      icon: "/assets/github.png",
      description:
        "I create new repos all the time with pitches and thoughts in the README.",
      link: "https://github.com/greg-maceachern12?tab=repositories",
    },
  ];

  const newsItems = [
    {
      title: "Introducing Microsoft Copilot",
      icon: "/assets/copilot.png",
      description:
        "Featured on major news outlets, such as the Verge, and WindowsCentral",
      link: "https://techcommunity.microsoft.com/t5/microsoft-365-blog/onenote-your-digital-notebook-reimagined-with-copilot/ba-p/3788442",
    },
    {
      title: "OneNote for Apple Vision Pro",
      icon: "/assets/ON.png",
      description: "Led the release of 0-1 platform, achieving a 7% market penetration rate.",
      link: "https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-onenote-now-available-on-apple-vision-pro/ba-p/4115959",
    },
    {
      title: "Office Home Feature on iOS",
      icon: "/assets/apple.png",
      description:
        "Filled up 10,000 testflight spots in less than one day for new iOS Office feature",
      link: "https://insider.microsoft365.com/zh-hk/blog/new-home-experience-in-onenote-for-iphone",
    },
    {
      title: "OneNote Product Update",
      icon: "/assets/ON.png",
      description: "Garnered over 50,000 views and ~700 shares",
      link: "https://techcommunity.microsoft.com/t5/microsoft-365-blog/what-s-new-and-what-s-coming-to-onenote-on-windows/ba-p/3966645",
    },
  ];

  return (
    <>
      <BackgroundVideo />
      <div className="container linear-inspired">
        <header className="header">
          <h1>Greg MacEachern</h1>
          <p>Product Manager | Software Engineer</p>
        </header>

        <nav className="nav">
          <ul>
            {["about", "projects", "news"].map((section) => (
              <li key={section}>
                <button
                  className={currentSection === section ? "active" : ""}
                  onClick={() => setCurrentSection(section)}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <main>
          {currentSection === "about" && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              className="about-section glass-effect"
            >
              <h2>
                <User /> About Me
              </h2>
              <div className="about-content">
                <img
                  src="/assets/head3.jpg"
                  alt="Greg MacEachern"
                  className="profile-image"
                />
                <div className="about-text">
                  <p className="intro-text">
                    Hello! I'm a Product Manager at Microsoft and trained Software Engineer with a knack for creating lovable user experiences.
                  </p>
                  <div className="skills-section glass-effect-lite">
                    <h3>My Expertise</h3>
                    <ul className="skills-list">
                    <li><Briefcase size={16} /> Product Management</li>
                    <li><Database size={16} /> Product Led Growth</li>
                      <li><Code size={16} /> React</li>
                      <li><Code size={16} /> Node</li>
                      <li><Code size={16} /> CSS and frameworks (Framer, Tailwind etc.)</li>
                      <li><Code size={16} /> Front-end Development</li>
                    </ul>
                  </div>
                  <p>
                    I love combining front-end craft building with the strategic aspects of product management. This unique blend allows me to not only define the problem space but also actively contribute to building the solution.
                  </p>
                  <div className="personal-info glass-effect-lite">
                    <p><MapPin size={16} /> Originally from Canada, now based in New York City 🇺🇸</p>
                    <p><GraduationCap size={16} /> Computer Engineering Degree with a Green Card</p>
                  </div>
                  <div className="contact-info glass-effect-lite">
                    <h3>Let's Connect</h3>
                    <div className="info-item">
                      <MailOpen size={20} />
                      <a href="mailto:gregmaceachern98@gmail.com">gregmaceachern98@gmail.com</a>
                    </div>
                    <div className="info-item">
                      <Linkedin size={20} />
                      <a href="https://www.linkedin.com/in/greg-maceachern/" target="_blank" rel="noreferrer">LinkedIn</a>
                    </div>
                    <div className="info-item">
                      <Github size={20} />
                      <a href="https://github.com/greg-maceachern12" target="_blank" rel="noreferrer">Github</a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}

          {currentSection === "projects" && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              className="projects-section"
            >
              <h2>
                <Code /> Projects
              </h2>
              <p>
                I love turning side projects into side hustles and exploring new
                exciting tech outside of the bounds of my day job.
                <br></br><br></br>
                Currently, I'm exploring all of the available AI APIs and making a little $ on the side.
              </p>
              <br></br>
              <div className="card-grid">
                {projects.map((project, index) => (
                  <Card key={index} {...project} />
                ))}
              </div>
            </motion.div>
          )}

          {currentSection === "news" && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              className="news-section"
            >
              <h2>
                <Newspaper /> News
              </h2>
              <p>
                I’ve written publications about upcoming features and updates in
                OneNote and M365! Check a few of them out here!
              </p>
              <br></br>
              <div className="card-grid">
                {newsItems.map((item, index) => (
                  <Card key={index} {...item} />
                ))}
              </div>
            </motion.div>
          )}
        </main>
      </div>
    </>
  );
};

export default PortfolioWebsite;
